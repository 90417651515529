// 制作 试题界面
<template>
  <div class="add">
    <el-page-header @back="goBack" content="增加试题">
    </el-page-header>
    <el-tabs v-model="activeName">
<!--      <el-tab-pane name="first">-->
<!--      <span slot="label"><i class="el-icon-circle-plus"></i>添加试题</span>-->
<!--      <section class="append">-->
<!--        <ul>-->
<!--          <li>-->
<!--            <span>题目类型:</span>-->
<!--            <el-select v-model="optionValue" placeholder="请选择题型" class="w150">-->
<!--              <el-option-->
<!--                v-for="item in options"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--          <li v-if="optionValue == '选择题'">-->
<!--            <span>所属章节：</span>-->
<!--            <el-input-->
<!--              placeholder="请输入对应章节"-->
<!--              v-model="postChange.section"-->
<!--              class="w150"-->
<!--              clearable>-->
<!--            </el-input>-->
<!--          </li>-->
<!--          <li v-if="optionValue == '填空题'">-->
<!--            <span>所属章节：</span>-->
<!--            <el-input-->
<!--              placeholder="请输入对应章节"-->
<!--              v-model="postFill.section"-->
<!--              class="w150"-->
<!--              clearable>-->
<!--            </el-input>-->
<!--          </li>-->
<!--          <li v-if="optionValue == '判断题'">-->
<!--            <span>所属章节：</span>-->
<!--            <el-input-->
<!--              placeholder="请输入对应章节"-->
<!--              v-model="postJudge.section"-->
<!--              class="w150"-->
<!--              clearable>-->
<!--            </el-input>-->
<!--          </li>-->
<!--          <li v-if="optionValue == '选择题'">-->
<!--            <span>难度等级:</span>-->
<!--            <el-select v-model="postChange.level" placeholder="选择难度等级" class="w150">-->
<!--              <el-option-->
<!--                v-for="item in levels"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--          <li v-if="optionValue == '填空题'">-->
<!--            <span>难度等级:</span>-->
<!--            <el-select v-model="postFill.level" placeholder="选择难度等级" class="w150">-->
<!--              <el-option-->
<!--                v-for="item in levels"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--          <li v-if="optionValue == '判断题'">-->
<!--            <span>难度等级:</span>-->
<!--            <el-select v-model="postJudge.level" placeholder="选择难度等级" class="w150">-->
<!--              <el-option-->
<!--                v-for="item in levels"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--          <li v-if="optionValue == '选择题'">-->
<!--            <span>正确选项:</span>-->
<!--            <el-select v-model="postChange.rightAnswer" placeholder="选择正确答案" class="w150">-->
<!--              <el-option-->
<!--                v-for="item in rights"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--        </ul>-->
<!--        &lt;!&ndash; 选择题部分 &ndash;&gt;-->
<!--        <div class="change" v-if="optionValue == '选择题'">-->
<!--          <div class="title">-->
<!--            <el-tag>题目:</el-tag><span>在下面的输入框中输入题目,形如&#45;&#45;DNS 服务器和DHCP服务器的作用是（）</span>-->
<!--            <el-input-->
<!--              type="textarea"-->
<!--              rows="4"-->
<!--              v-model="postChange.question"-->
<!--              placeholder="请输入题目内容"-->
<!--              resize="none"-->
<!--              class="answer">-->
<!--            </el-input>-->
<!--          </div>-->
<!--          <div class="options">-->
<!--            <ul>-->
<!--              <li>-->
<!--                <el-tag type="success">A</el-tag>-->
<!--                <el-input-->
<!--                  placeholder="请输入选项A的内容"-->
<!--                  v-model="postChange.answerA"-->
<!--                  clearable="">-->
<!--                </el-input>-->
<!--              </li>-->
<!--              <li>-->
<!--                <el-tag type="success">B</el-tag>-->
<!--                <el-input-->
<!--                  placeholder="请输入选项B的内容"-->
<!--                  v-model="postChange.answerB"-->
<!--                  clearable="">-->
<!--                </el-input>-->
<!--              </li>-->
<!--              <li>-->
<!--                <el-tag type="success">C</el-tag>-->
<!--                <el-input-->
<!--                  placeholder="请输入选项C的内容"-->
<!--                  v-model="postChange.answerC"-->
<!--                  clearable="">-->
<!--                </el-input>-->
<!--              </li>-->
<!--              <li>-->
<!--                <el-tag type="success">D</el-tag>-->
<!--                <el-input-->
<!--                  placeholder="请输入选项D的内容"-->
<!--                  v-model="postChange.answerD"-->
<!--                  clearable="">-->
<!--                </el-input>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--          <div class="title">-->
<!--            <el-tag>解析:</el-tag><span>在下面的输入框中输入题目解析</span>-->
<!--            <el-input-->
<!--              type="textarea"-->
<!--              rows="4"-->
<!--              v-model="postChange.analysis"-->
<!--              placeholder="请输入答案解析"-->
<!--              resize="none"-->
<!--              class="answer">-->
<!--            </el-input>-->
<!--          </div>-->
<!--          <div class="submit">-->
<!--            <el-button type="primary" @click="changeSubmit()">立即添加</el-button>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; 填空题部分 &ndash;&gt;-->
<!--        <div class="change fill" v-if="optionValue == '填空题'">-->
<!--          <div class="title">-->
<!--            <el-tag>题目:</el-tag><span>输入题目,形如&#45;&#45;从计算机网络系统组成的角度看，计算机网络可以分为()和()。注意需要考生答题部分一定要用括号（英文半角）括起来。</span>-->
<!--            <el-input-->
<!--              type="textarea"-->
<!--              rows="4"-->
<!--              v-model="postFill.question"-->
<!--              placeholder="请输入题目内容"-->
<!--              resize="none"-->
<!--              class="answer">-->
<!--            </el-input>-->
<!--          </div>-->
<!--          <div class="fillAnswer">-->
<!--            <el-tag>正确答案:</el-tag>-->
<!--            <el-input v-model="postFill.answer"></el-input>-->
<!--          </div>-->
<!--          <div class="title analysis">-->
<!--            <el-tag type="success">解析:</el-tag><span>下方输入框中输入答案解析</span>-->
<!--            <el-input-->
<!--              type="textarea"-->
<!--              rows="4"-->
<!--              v-model="postFill.analysis"-->
<!--              placeholder="请输入答案解析"-->
<!--              resize="none"-->
<!--              class="answer">-->
<!--            </el-input>-->
<!--          </div>-->
<!--          <div class="submit">-->
<!--            <el-button type="primary" @click="fillSubmit()">立即添加</el-button>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; 判断题 &ndash;&gt;-->
<!--        <div class="change judge" v-if="optionValue == '判断题'">-->
<!--          <div class="title">-->
<!--            <el-tag>题目:</el-tag><span>在下面的输入框中输入题目</span>-->
<!--            <el-input-->
<!--              type="textarea"-->
<!--              rows="4"-->
<!--              v-model="postJudge.question"-->
<!--              placeholder="请输入题目内容"-->
<!--              resize="none"-->
<!--              class="answer">-->
<!--            </el-input>-->
<!--          </div>-->
<!--          <div class="judgeAnswer">-->
<!--            <el-radio v-model="postJudge.answer" label="T">正确</el-radio>-->
<!--            <el-radio v-model="postJudge.answer" label="F">错误</el-radio>-->
<!--          </div>-->
<!--          <div class="title">-->
<!--            <el-tag>解析:</el-tag><span>在下面的输入框中输入题目解析</span>-->
<!--            <el-input-->
<!--              type="textarea"-->
<!--              rows="4"-->
<!--              v-model="postJudge.analysis"-->
<!--              placeholder="请输入答案解析"-->
<!--              resize="none"-->
<!--              class="answer">-->
<!--            </el-input>-->
<!--          </div>-->
<!--          <div class="submit">-->
<!--            <el-button type="primary" @click="judgeSubmit()">立即添加</el-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </section>-->
<!--    </el-tab-pane>-->
    <el-tab-pane name="first">
<!--      v-if="isuse()"-->
      <span slot="label"><i class="iconfont icon-daoru-tianchong"></i>在线组卷</span>
      <div class="box">
        <ul>
          <li>
            <span>试题难度:</span>
            <el-select v-model="difficultyValue" placeholder="试题难度" class="w150">
              <el-option
                v-for="item in difficulty"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <span>选择题数量：</span> <el-input type="text" v-model="changeNumber"></el-input>
          </li>
          <li>
            <span>填空题数量：</span> <el-input type="text" v-model="fillNumber"></el-input>
          </li>
          <li>
            <span>判断题数量：</span> <el-input type="text" v-model="judgeNumber"></el-input>
          </li>
          <li>
            题库选择：
          </li>
          <li>
            <el-transfer
                    filterable
                    :filter-method="filterMethod"
                    filter-placeholder="请输入城市拼音"
                    v-model="value"
                    :data="data">
            </el-transfer>
          </li>
          <li>
            <el-button type="primary" @click="create()">立即组卷</el-button>
          </li>
        </ul>
      </div>
    </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      value: [],
      filterMethod(query, item) {
        return item.pinyin.indexOf(query) > -1;
      },
      tag:false,
      changeNumber: null, //选择题出题数量
      fillNumber: null, //填空题出题数量
      judgeNumber: null, //判断题出题数量
      activeName: 'first',  //活动选项卡
      options: [ //题库类型
        {
          value: '选择题',
          label: '选择题'
        },
        {
          value: '填空题',
          label: '填空题'
        },
        {
          value: '判断题',
          label: '判断题'
        },
      ],
      difficulty: [ //试题难度
        {
          value: 1,
          label: '简单'
        },
        {
          value: 3,
          label: '一般'
        },
        {
          value: 5,
          label: '困难'
        }
      ],
      difficultyValue: 1,
      levels: [ //难度等级
        {
          value: '1',
          label: '1'
        },
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        },
        {
          value: '5',
          label: '5'
        },
      ],
      rights: [ //正确答案
        {
          value: 'A',
          label: 'A'
        },
        {
          value: 'B',
          label: 'B'
        },
        {
          value: 'C',
          label: 'C'
        },
        {
          value: 'D',
          label: 'D'
        },
      ],
      paperId: null,
      optionValue: '选择题', //题型选中值
      subject: '', //试卷名称用来接收路由参数
      postChange: { //选择题提交内容
        subject: '', //试卷名称
        level: '', //难度等级选中值 
        rightAnswer: '', //正确答案选中值
        section: '', //对应章节
        question: '', //题目
        analysis: '', //解析
        answerA: '',
        answerB: '',
        answerC: '',
        answerD: '',
      },
      postFill: { //填空题提交内容
        subject: '', //试卷名称
        level: '', //难度等级选中值 
        answer: '', //正确答案
        section: '', //对应章节
        question: '', //题目
        analysis: '', //解析
      },
      postJudge: { //判断题提交内容
        subject: '', //试卷名称
        level: '', //难度等级选中值 
        answer: '', //正确答案
        section: '', //对应章节
        question: '', //题目
        analysis: '', //解析
      },
      postPaper: { //考试管理表对应字段
        paperId: null,
        questionType: null, // 试卷类型 1--选择题  2--填空题   3--判断题
        questionId: null,
      }
    };
  },
  created() {
    this.getParams()
  },
  methods: {
    // handleClick(tab, event) {
    //   console.log(tab, event);
    // },
    create() {
      // 看一下是否可以传递过来 试题难度
      console.log(this.difficultyValue)  ;
      console.log(this.paperId) ;
      // 创建时，先进行一系列的判断
      if(this.changeNumber == '' || this.changeNumber <= 0){
        this.$message({
          type:'warning',
          message:'选择题数量不符合规范'
        }) ;
          return ;
      }
      if(this.judgeNumber == '' || this.judgeNumber <= 0){
        this.$message({
          type:'warning',
          message:'判断题数量不符合规范'
        }) ;
        return ;
      }
      if(this.fillNumber == '' || this.fillNumber <= 0){
        this.$message({
          type:'warning',
          message:'填空题数量不符合规范'
        }) ;
        return ;
      }
      console.log(this.value) ;
      if(this.value.length == 0){
        this.$message({
          type:'warning',
          message:'至少要选择一个题库'
        }) ;
        return;
      }
      // 使用遗传算法进行智能 组卷
      this.$axios({
        url: '/item',
        method: 'post',
        data: {
          changeNumber: this.changeNumber,
          fillNumber: this.fillNumber,
          judgeNumber: this.judgeNumber,
          paperId: this.paperId,
          level: this.difficultyValue,
          value:this.value
        }
      }).then(res => {
        console.log(res)
        // let data = res.data
        if(res.code==200){
          setTimeout(() => {
            // this.$router.push({path: '/selectAnswer'})
            this.$router.push('/addAnswer') ;
          },1000)
           this.$message({
            message: res.message,
            type: 'success'
          })
        }else if(res.code==500){
            this.$message({
            message: res.message,
            type: 'error'
          })
        }

      })
    },
    // 判断是否 已经使用过一次 自动组卷系统
    isuse(){
      this.$axios({
        url:'/getExamState',
        method:'post',
        params:{
          id:this.paperId
        }
      }).then(res =>{
        if(res.code == 200){
          console.log(res.data.length) ;
          if(res.data.length != 0){
            this.tag = false;
          }else {
            this.tag = true ;
          }
        }
      }) ;
      return this.tag;
    },
    // 初始化数据
    getParams() {
      // 获得从上个组件中获得的参数
      let subject = this.$route.query.subject //获取试卷名称
      let paperId = this.$route.query.paperId //获取paperId
      this.paperId = paperId
      this.subject = subject
      this.postPaper.paperId = paperId
      let uid = this.$cookies.get("user").id  ;
    //  初始化 穿梭框 ,  查选当前用户所拥有的全部的题库
      this.$axios({
        url:'getAllTk',
        method:'post',
        params:{
          uid:uid,
        }

      }).then(res =>{
        if(res.code == 200){
          let info = res.data;
          info.forEach((data,index)=>{
            this.data.push({
              label:data.name,
              key:data.id ,
              pinyin:data.name
            })
          })
        }

      }) ;
    },
    changeSubmit(){ // 增加选择题

    },
    fillSubmit(){ // 增加填空题

    },
    judgeSubmit(){ // 增加判断题

    },

    // changeSubmit() { //选择题题库提交
    //   this.postChange.subject = this.subject
    //   this.$axios({ //提交数据到选择题题库表
    //     url: '/MultiQuestion',
    //     method: 'post',
    //     data: {
    //       ...this.postChange
    //     }
    //   }).then(res => { //添加成功显示提示
    //     let status = res.code
    //     if(status == 200) {
    //       this.$message({
    //         message: '已添加到题库',
    //         type: 'success'
    //       })
    //       this.postChange = {}
    //     }
    //   }).then(() => {
    //     this.$axios(`/multiQuestionId`).then(res => { //获取当前题目的questionId
    //       let questionId = res.data.questionId
    //       this.postPaper.questionId = questionId
    //       this.postPaper.questionType = 1
    //       this.$axios({
    //         url: '/paperManage',
    //         method: 'Post',
    //         data: {
    //           ...this.postPaper
    //         }
    //       })
    //     })
    //   })
    // },
    // fillSubmit() { //填空题提交
    //   this.postFill.subject = this.subject
    //   this.$axios({
    //     url: '/fillQuestion',
    //     method: 'post',
    //     data: {
    //       ...this.postFill
    //     }
    //   }).then(res => {
    //     let status = res.code
    //     if(status == 200) {
    //       this.$message({
    //         message: '已添加到题库',
    //         type: 'success'
    //       })
    //       this.postFill = {}
    //     }
    //   }).then(() => {
    //     this.$axios(`/fillQuestionId`).then(res => { //获取当前题目的questionId
    //       let questionId = res.data.questionId
    //       this.postPaper.questionId = questionId
    //       this.postPaper.questionType = 2
    //       this.$axios({
    //         url: '/paperManage',
    //         method: 'Post',
    //         data: {
    //           ...this.postPaper
    //         }
    //       })
    //     })
    //   })
    // },
    goBack(){
        this.$router.push('/addAnswer') ;
    },
    // judgeSubmit() { //判断题提交
    //   this.postJudge.subject = this.subject
    //   this.$axios({
    //     url: '/judgeQuestion',
    //     method: 'post',
    //     data: {
    //       ...this.postJudge
    //     }
    //   }).then(res => {
    //     let status = res.code
    //     if(status == 200) {
    //       this.$message({
    //         message: '已添加到题库',
    //         type: 'success'
    //       })
    //       this.postJudge = {}
    //     }
    //   }).then(() => {
    //     this.$axios(`/judgeQuestionId`).then(res => { //获取当前题目的questionId
    //       let questionId = res.data.questionId
    //       this.postPaper.questionId = questionId
    //       this.postPaper.questionType = 3
    //       this.$axios({
    //         url: '/paperManage',
    //         method: 'Post',
    //         data: {
    //           ...this.postPaper
    //         }
    //       })
    //     })
    //   })
    // }
  },
};
</script>

<style lang="scss" scoped>
.add {
  margin: 0px 40px;
  .box {
    padding: 0px 20px;
    ul li {
      margin: 10px 0px;
      display: flex;
      align-items: center;
      .el-input {
        width: 6%;
      }
      .w150 {
        margin-left: 20px;
        width: 7%;
      }
    }
  }
  .el-icon-circle-plus {
    margin-right: 10px;
  }
  .icon-daoru-tianchong {
    margin-right: 10px;
  }
  .append {
    margin: 0px 20px;
    ul {
      display: flex;
      align-items: center;
      li {
        margin-right: 20px;
      }
    }
    .change {
      margin-top: 20px;
      padding: 20px 16px;
      background-color: #E7F6F6;
      border-radius: 4px;
      .title {
        padding-left: 6px;
        color: #2f4f4f;
        span:nth-child(1) {
          margin-right: 6px;
        }
        .answer {
          margin: 20px 0px 20px 8px;
        }
        .el-textarea {
          width: 98% !important;
        }
      }
      .options {
        ul {
          display: flex;
          flex-direction: column;
        }
        ul li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 98%;
          margin: 10px 0px;
          span {
            margin-right: 20px;
          }
        }
      }
      .submit {
        display: flex;
        justify-content: center;
        align-items: center;
      }        
    }
    .fill {
      .fillAnswer {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-right: 6px;
        }
        .el-input {
          width: 91% !important;
        }
      }
      .analysis {
        margin-top: 20px;
        margin-left: 5px;
      }
    }
    .judge {
      .judgeAnswer {
        margin-left: 20px;
        margin-bottom: 20px;
      }
    }
    .w150 {
      width: 150px;
    }
    li:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>


